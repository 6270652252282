import Vue from 'vue'
import VueRouter from 'vue-router'
const _404 = () => import(/* webpackChunkName: "unusual" */'@/views/unusual/404.vue')
const Login = () => import(/* webpackChunkName: "login" */'@/views/login/index.vue')
const Layout = () => import(/* webpackChunkName: "layout" */'@/layout/index.vue')
const UserList = () => import(/* webpackChunkName: "userList" */'@/views/userList/index.vue')
const Upload = () => import(/* webpackChunkName: "upload" */'@/views/upload/index.vue')
const Data = () => import(/* webpackChunkName: "data" */'@/views/data/index.vue')
const Excel = () => import(/* webpackChunkName: "excel" */'@/views/excel/index.vue')

Vue.use(VueRouter)

// 解决重复点击路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/404',
    name: '404',
    component: _404
  },
  {
    path: '/excel',
    name: 'excel',
    component: Excel
  },
  {
    path: '/',
    component: Layout,
    redirect: '/index',
    children: [
      {
        path: '/index',
        component: UserList
      },
      {
        path: '/upload',
        component: Upload
      },
      {
        path: '/data',
        component: Data
      }
    ]
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
